import { Link, useOutletContext } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "~/router";
import { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowDownOnSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ChantiersService } from "~/services/chantiersService";
import { ClientsService } from "~/services/clientsService";
import { phases_chantiers } from "~/utils/refs";
import InputSearchBAN from "~/components/InputSearchBAN";

export default function AddClient() {
  // Permets la redirection apres la création
  const navigate = useNavigate();

  // State servant au stockage des données de la personnes à créer
  const [stateData, setStateData] = useState({
    title: "",
    address: "",
    city: "",
    postal_code: "",
    phone1: "",
    phone2: "",
    exterior: false,
    amount_no_taxes: 0,
    external_id: "",
    description: "",
    phase: "",
    quote: "",
    man_days: 0,
    archived: false,
    field1: "",
    field2: "",
    field3: "",
    client_id: null,
    domains: [],
    color: "#FFFFFF",
  });

  // Création du service API et appel du hook utilisé pour la création de chantiers
  const chantiersService = new ChantiersService();
  const {
    data: apiCreateData,
    error: apiCreateError,
    isLoading: apiCreateIsLoading,
    callToAction: apiCreateHandler,
  } = chantiersService.useCreate(stateData);

  /* le service d'API et le hook permettant de récupérer toutes les clients */
  const clientsService = new ClientsService();
  const {
    data: apiGetDataClients,
    error: apiGetErrorClients,
    isLoading: apiGetIsLoadingClients,
    callToAction: apiGetHandlerClients,
  } = clientsService.useGetAll();

  // Permet de définir le titre de la page
  const [_, setPageTitle] = useOutletContext();

  // Au chargement, on change le titre de la page
  useEffect(() => {
    setPageTitle("Ajout d'un chantier");
  });

  useEffect(() => {
    if (apiCreateData) navigate("/chantiers");
  }, [apiCreateData]);

  useEffect(() => {
    if (stateData.client_id) {
      let find = apiGetDataClients.find(
        (client) => client.id == stateData.client_id
      );
      if (find) {
        console.log(find);
        setStateData({
          ...stateData,
          address: find.address,
          city: find.city,
          postal_code: find.postal_code,
          phone1: find.phone1,
          phone2: find.phone2,
        });
      }
    }
  }, [stateData.client_id]);

  // En cas d'erreur et code 401 (not authenticated) : on redirige vers la page de login
  if (apiCreateError) {
    if (apiCreateError.response && apiCreateError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  return (
    <>
      {/* Header affichant le bouton retour, le message d'erreur si il en a un, et le bouton sauvegarder */}
      <div className="w-full md:w-2/3 flex justify-between text-center">
        <Link
          className="rounded-md border border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500 p-4"
          to={`/chantiers/`}
          title="Retour a la liste"
        >
          <ArrowLeftIcon className="h-4 inline" />
        </Link>

        {apiCreateError && (
          <span className="text-red-600">{apiCreateError.message}</span>
        )}

        <button
          className={`rounded-md border border-green-600 ${
            apiCreateIsLoading
              ? "bg-green-200"
              : "hover:bg-green-600 hover:text-white"
          } transition-colors duration-500 p-4`}
          onClick={apiCreateHandler}
          disabled={apiCreateIsLoading}
        >
          <ArrowDownOnSquareIcon className="h-4 inline" />
        </button>
      </div>
      {/* La table affichant le formulaire */}
      <table className="w-full md:w-2/3 text-2xl mt-4 table-auto">
        <tbody>
          <tr>
            <td className="font-bold py-4">Couleur</td>
            <td>
              <input
                type="color"
                name="color"
                className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700"
                value={stateData.color}
                onChange={(e) =>
                  setStateData({ ...stateData, color: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Phase</td>
            <td>
              <select
                data-te-select-initid="phase"
                name="phase"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                //placeholder="xxx"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    phase: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option value=""></option>
                {phases_chantiers.map((phase) => {
                  return <option value={phase.name}>{phase.name}</option>;
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Client</td>
            <td>
              <select
                data-te-select-initid="client_id"
                name="client_id"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                //placeholder="xxx"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    client_id: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option value=""></option>
                {apiGetDataClients &&
                  apiGetDataClients.map((client) => {
                    return (
                      <option value={client.id}>
                        {client.name} ({client.city})
                      </option>
                    );
                  })}
              </select>
            </td>
            <td>
              <button onClick={() => navigate("/clients/add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Titre</td>
            <td>
              <input
                id="title"
                name="title"
                className="rounded-lg w-full"
                type="text"
                value={stateData.title}
                onChange={(e) =>
                  setStateData({ ...stateData, title: e.target.value })
                }
              />
            </td>
          </tr>
          <InputSearchBAN data={stateData} maj={setStateData} />
          <tr>
            <td className="font-bold py-4">Téléphone 1</td>
            <td>
              <input
                id="phone1"
                name="phone1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone1}
                onChange={(e) =>
                  setStateData({ ...stateData, phone1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Téléphone 2</td>
            <td>
              <input
                id="phone2"
                name="phone2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone2}
                onChange={(e) =>
                  setStateData({ ...stateData, phone2: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Numéro du devis</td>
            <td>
              <input
                id="quote"
                name="quote"
                className="rounded-lg w-full"
                type="text"
                value={stateData.quote}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    quote: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Devis HT</td>
            <td>
              <input
                id="amount_no_taxes"
                name="amount_no_taxes"
                className="rounded-lg w-full"
                type="text"
                value={stateData.amount_no_taxes}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    amount_no_taxes: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Nombre de jours etimés</td>
            <td>
              <input
                id="man_days"
                name="man_days"
                className="rounded-lg w-full"
                type="text"
                value={stateData.man_days}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    man_days: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Description des travaux</td>
            <td>
              <textarea
                id="description"
                name="description"
                className="rounded-lg w-full"
                type="text"
                value={stateData.description}
                onChange={(e) =>
                  setStateData({ ...stateData, description: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">
              Identifiant Externe (Bon commande, ID, Numéro)
            </td>
            <td>
              <input
                id="external_id"
                name="external_id"
                className="rounded-lg w-full"
                type="text"
                value={stateData.external_id}
                onChange={(e) =>
                  setStateData({ ...stateData, external_id: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 1</td>
            <td>
              <input
                id="field1"
                name="field1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field1}
                onChange={(e) =>
                  setStateData({ ...stateData, field1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 2</td>
            <td>
              <input
                id="field2"
                name="field2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field2}
                onChange={(e) =>
                  setStateData({ ...stateData, field2: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 3</td>
            <td>
              <input
                id="field3"
                name="field3"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field3}
                onChange={(e) =>
                  setStateData({ ...stateData, field3: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Chantier en extérieur</td>
            <td>
              <input
                id="exterior"
                name="exterior"
                type="checkbox"
                className="
                  border border-gray-300
                  placeholder-gray-500 text-gray-900 
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                //placeholder="xxx"
                checked={stateData.exterior}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    exterior: e.target.checked ? true : false,
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
