import { Link, useOutletContext } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "~/router";
import { ClientsService } from "~/services/clientsService";
import { ChantiersService } from "~/services/chantiersService";
import { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import { BeatLoader } from "react-spinners";
import { phases_chantiers } from "~/utils/refs";
import InputSearchBAN from "~/components/InputSearchBAN";

export default function EditChantier() {
  /* useParams() est un hook permettant de récupérer les variables GET */
  const { id } = useParams();

  /* hook utile a la redirection, après l'édition ou pour le retour à la liste */
  const navigate = useNavigate();

  /* Le state stockant les données de la personnes à modifier et utilisé dans le formulaire */
  const [stateData, setStateData] = useState({
    title: "",
    address: "",
    city: "",
    postal_code: "",
    phone1: "",
    phone2: "",
    exterior: false,
    amount_no_taxes: 0,
    external_id: "",
    description: "",
    phase: "",
    archived: false,
    quote: "",
    man_days: 0,
    field1: "",
    field2: "",
    field3: "",
    client_id: null,
    domains: [],
    color: "#FFFFFF",
  });

  /* Création du service API pour les personnes et utilisation de deux hook :
       - Le hook de récupération des données du client à modifier
       - Le hook de modification des données */
  const chantiersService = new ChantiersService();
  const {
    data: apiGetData,
    error: apiGetError,
    isLoading: apiGetIsLoading,
  } = chantiersService.useGetOne(id);

  const {
    data: apiUpdateData,
    error: apiUpdateError,
    isLoading: apiUpdateIsLoading,
    callToAction: apiUpdate,
  } = chantiersService.useUpdate(id, stateData);

  /* le service d'API et le hook permettant de récupérer toutes les clients */
  const clientsService = new ClientsService();
  const {
    data: apiGetDataClients,
    error: apiGetErrorClients,
    isLoading: apiGetIsLoadingClients,
    callToAction: apiGetHandlerClients,
  } = clientsService.useGetAll();

  /* Permet le changement du titre de la page */
  const [_, setPageTitle] = useOutletContext();

  /* Au chargement, les données de la personne à modifier sont récupérées, stockées dans le résultat du hook
       et le useEffect va les mettre dans la variable d'état et plus de modifier le titre de la page */
  useEffect(() => {
    if (apiGetData && apiGetData.id) {
      setPageTitle(`Edition du chantier ${apiGetData.title}`);
      setStateData({
        title: apiGetData.title,
        address: apiGetData.address,
        city: apiGetData.city,
        postal_code: apiGetData.postal_code,
        phone1: apiGetData.phone1,
        phone2: apiGetData.phone2,
        exterior: apiGetData.exterior,
        amount_no_taxes: apiGetData.amount_no_taxes,
        external_id: apiGetData.external_id,
        quote: apiGetData.quote,
        description: apiGetData.description,
        phase: apiGetData.phase,
        man_days: apiGetData.man_days,
        archived: apiGetData.archived,
        field1: apiGetData.field1,
        field2: apiGetData.field2,
        field3: apiGetData.field3,
        client_id: apiGetData.client_id,
        domains: apiGetData.domains,
        color: apiGetData.color,
      });
    }
  }, [apiGetData]);

  /* Si 401 (not authenticated) à la récupération de l'utilisateur, on redirige vers le logout */
  if (apiGetError) {
    if (apiGetError.response && apiGetError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  return (
    <>
      {/* Le header contenant le bouton de retour, le loader, les erreurs si il y en a, et le bouton de sauvegarde */}
      <div className="w-full md:w-2/3 flex justify-between text-center">
        <Link
          className="rounded-md border border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500 p-4"
          to={`/chantiers`}
          title="Retour a la liste"
        >
          <ArrowLeftIcon className="h-4 inline" />
        </Link>

        <BeatLoader size={10} loading={apiGetIsLoading} className="ml-8" />

        {apiGetError && (
          <span className="text-red-600">{apiGetError.message}</span>
        )}
        {apiUpdateError && (
          <span className="text-red-600">{apiUpdateError.message}</span>
        )}

        <button
          className={`rounded-md border border-green-600 ${
            apiUpdateIsLoading
              ? "bg-green-200"
              : "hover:bg-green-600 hover:text-white"
          } transition-colors duration-500 p-4`}
          onClick={apiUpdate}
          disabled={apiUpdateIsLoading}
        >
          <ArrowDownOnSquareIcon className="h-4 inline" />
        </button>
      </div>

      {/* Le formulaire permettant de modifier */}
      <table className="w-full md:w-2/3 text-2xl mt-4 table-auto">
        <tbody>
          <tr>
            <td className="font-bold py-4">Couleur</td>
            <td>
              <input
                type="color"
                name="color"
                className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700"
                value={stateData.color}
                onChange={(e) =>
                  setStateData({ ...stateData, color: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Phase</td>
            <td>
              <select
                data-te-select-initid="phase"
                name="phase"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    phase: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option value=""></option>
                {phases_chantiers.map((phase) => {
                  return (
                    <option
                      selected={stateData.phase == phase.name ? true : false}
                      value={phase.name}
                    >
                      {phase.name}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Client</td>
            <td>
              <select
                data-te-select-initid="client_id"
                name="client_id"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    client_id: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option
                  selected={stateData.client_id == null ? true : false}
                  value=""
                ></option>
                {apiGetDataClients &&
                  apiGetDataClients.map((client) => {
                    return (
                      <option
                        selected={
                          stateData.client_id == client.id ? true : false
                        }
                        value={client.id}
                      >
                        {client.name} ({client.city})
                      </option>
                    );
                  })}
              </select>
            </td>
            <td>
              <button onClick={() => navigate("/clients/add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Titre</td>
            <td>
              <input
                id="title"
                name="title"
                className="rounded-lg w-full"
                type="text"
                value={stateData.title}
                onChange={(e) =>
                  setStateData({ ...stateData, title: e.target.value })
                }
              />
            </td>
          </tr>
          <InputSearchBAN data={stateData} maj={setStateData} />
          <tr>
            <td className="font-bold py-4">Téléphone 1</td>
            <td>
              <input
                id="phone1"
                name="phone1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone1}
                onChange={(e) =>
                  setStateData({ ...stateData, phone1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Téléphone 2</td>
            <td>
              <input
                id="phone2"
                name="phone2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone2}
                onChange={(e) =>
                  setStateData({ ...stateData, phone2: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Numéro du devis</td>
            <td>
              <input
                id="quote"
                name="quote"
                className="rounded-lg w-full"
                type="text"
                value={stateData.quote}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    quote: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Devis HT</td>
            <td>
              <input
                id="amount_no_taxes"
                name="amount_no_taxes"
                className="rounded-lg w-full"
                type="text"
                value={stateData.amount_no_taxes}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    amount_no_taxes: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Nombre de jours etimés</td>
            <td>
              <input
                id="man_days"
                name="man_days"
                className="rounded-lg w-full"
                type="text"
                value={stateData.man_days}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    man_days: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Description des travaux</td>
            <td>
              <textarea
                id="description"
                name="description"
                className="rounded-lg w-full"
                type="text"
                value={stateData.description}
                onChange={(e) =>
                  setStateData({ ...stateData, description: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">
              Identifiant Externe (Bon commande, ID, Numéro)
            </td>
            <td>
              <input
                id="external_id"
                name="external_id"
                className="rounded-lg w-full"
                type="text"
                value={stateData.external_id}
                onChange={(e) =>
                  setStateData({ ...stateData, external_id: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 1</td>
            <td>
              <input
                id="field1"
                name="field1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field1}
                onChange={(e) =>
                  setStateData({ ...stateData, field1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 2</td>
            <td>
              <input
                id="field2"
                name="field2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field2}
                onChange={(e) =>
                  setStateData({ ...stateData, field2: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Champs Personnalisé 3</td>
            <td>
              <input
                id="field3"
                name="field3"
                className="rounded-lg w-full"
                type="text"
                value={stateData.field3}
                onChange={(e) =>
                  setStateData({ ...stateData, field3: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Chantier en extérieur</td>
            <td>
              <input
                id="exterior"
                name="exterior"
                type="checkbox"
                className="
                  border border-gray-300
                  placeholder-gray-500 text-gray-900 
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                //placeholder="xxx"
                checked={stateData.exterior}
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    exterior: e.target.checked ? true : false,
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
